import { differenceInDays, format } from "date-fns";

const DATE_FORMAT = "yyyy-MM-dd";
const GAP_DAYS = 3;
const TICK_DATES_LABELS = ["Engagement", "Launch", "Slate Delivery", "Placement Start", "Present"];

const getTickDateKey = (date, tickDates) => {
  let diff;

  const tickKeyIndex = Object.values(tickDates).findIndex((tickDate) => {
    const difference = differenceInDays(new Date(date), new Date(tickDate));

    const mod = Math.abs(difference);

    if (mod <= GAP_DAYS) {
      diff = difference;
      return true;
    } else {
      diff = undefined;
    }
  });

  return tickKeyIndex && { tickDatesKey: TICK_DATES_LABELS[tickKeyIndex], diff };
};

const CONFIG = {
  margins: { top: 60, right: 40, bottom: 160, left: 105 },
  theme: {
    background: "#fff",
    grid: {
      line: {
        stroke: "rgba(239, 239, 239, 0.85)",
        strokeWidth: 1
      }
    }
  },
  xScale: {
    type: "time",
    format: "%Y-%m-%d",
    useUTC: false,
    precision: "day"
  },
  xFormat: "time:%Y-%m-%d",
  yScale: {
    type: "linear",
    stacked: false
  },
  axisLeft: {
    tickSize: 0,
    tickPadding: 15
  },
  getAxisBottom: (tickDates) => ({
    tickRotation: -45,
    tickTok: tickDates,
    format: (currentDate) => {
      const formattedDate = `${format(currentDate, DATE_FORMAT)}`;
      const { tickDatesKey, diff } = getTickDateKey(formattedDate, tickDates);

      const isNegative = (number) => Math.sign(number) === -1;
      const mod = Math.abs(diff);

      const prepositions = isNegative(diff) ? "to" : "from";
      const prepend = diff === 0 ? "" : `${mod} days ${prepositions} `;

      return tickDatesKey ? `(${prepend}${tickDatesKey}) ${formattedDate}` : `${formattedDate}`;
    }
  })
};

export { CONFIG };
