import { colors } from "../../../styles/config";

export const Line = ({ series, lineGenerator, xScale, yScale }) => {
  return series?.map(({ id, data }) => {
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={colors.variner}
        style={{ strokeWidth: 3 }}
      />
    );
  });
};
