/* eslint-disable react/prop-types */
import { colors } from "../../../styles/config";

const CustomSymbol = ({ data, submissionView, events }) => {
  var { size, borderWidth, borderColor } = data;
  var strokeColor = colors.variner;

  if (submissionView !== "recruiter") {
    for (const e of events) {
      if (data.datum.xFormatted === e.start) {
        borderColor = e.color;
        strokeColor = "white";
      }
    }
  } else {
    strokeColor = "white";
  }

  return (
    <g>
      <circle
        r={size}
        strokeWidth={borderWidth}
        stroke={strokeColor}
        fill={borderColor}
        fillOpacity={1}
      />
    </g>
  );
};

export { CustomSymbol };
