import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import ReportingFiltersMenu from "./ReportingFiltersMenu";
import StatCard from "./Cards/StatCard";

import { SubmissionsChart } from "./components/SubmissionsChart";

const prettifyOwnerName = (ownerObject) => {
  return `${ownerObject.firstName} ${ownerObject.lastName}`;
};

// eslint-disable-next-line react/prop-types
export default function ReportingJobSubmissions({ submissionView, handleDataPointClick }) {
  const [currentlyViewing, setCurrentlyViewing] = useState(null);
  const [filters, setFilters] = useState([]);

  const { submissions } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (!currentlyViewing) {
      setFilters([]);
    } else {
      let newFilters = [];
      if (currentlyViewing) {
        newFilters = [
          ...newFilters,
          {
            icon: PersonOutlineIcon,
            label: `${currentlyViewing.firstName} ${currentlyViewing.lastName}`,
            onClick: () => {
              console.log("chip clicked");
            },
            onDelete: () => {
              setCurrentlyViewing(null);
            }
          }
        ];
      }
      setFilters(newFilters);
    }
  }, [currentlyViewing]);

  const findBy = (sub) =>
    prettifyOwnerName(sub.sendingUser) === prettifyOwnerName(currentlyViewing);

  const totalSubmissions = currentlyViewing
    ? submissions?.filter(findBy)?.length
    : submissions?.length;

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        ml={4}
        mb={2}
        style={{ marginTop: "-44px" }}
        width={200}
      >
        <StatCard
          shadow={false}
          stat={{
            title: "Submissions",
            total: totalSubmissions
          }}
        />
      </Box>

      {filters && filters.length > 0 && (
        <Box px={4}>
          <ReportingFiltersMenu filters={filters} setHasOwnerFilter={setFilters} />
        </Box>
      )}

      <Box height={440}>
        <SubmissionsChart
          currentlyViewing={currentlyViewing}
          submissionView={submissionView}
          setCurrentlyViewing={setCurrentlyViewing}
          handleDataPointClick={handleDataPointClick}
        />
      </Box>
    </Box>
  );
}
