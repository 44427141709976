import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import size from "lodash/size";
import find from "lodash/find";

import { rangeSelectionMap } from "@/helpers/dateRanges";
import DashboardModuleCard from "../DashboardModuleCard";
import makeCompactSearchesTableData from "@/helpers/makeCompactSearchesTableData";
import ActiveSearchesTableCompact from "@/components/tables/ActiveSearchesTableCompact";
import FilterDropdown from "../FilterDropdown";
import SubmissionsBarDrawer from "../SubmissionsBarDrawer";
import { prettyName, superAccess } from "@/helpers/user";
import { ACTIVE_SEARCHES_TYPE_MAP, ACTIVE_SEARCHES_LABELS_MAP } from "@/helpers/search";
import { isAdminSelector, userSelector } from "models/selectors/user";
import { HTTP_STATUS_CODES } from "@/constants";

// eslint-disable-next-line react/prop-types
export default function ActiveSearchesCompactSection({ handleDateRangeSelectionChange }) {
  // ↓ styling ↓

  // ↓ redux ↓
  const dispatch = useDispatch();

  const { user, internalUsers } = useSelector(userSelector);

  const isAdmin = useSelector(isAdminSelector);

  const { searches, searchesSubmissions, searchesCalls, loading } = useSelector(
    (state) => state.searches
  );

  const { rangeSelection, customDateRange } = useSelector((state) => state.app);

  // ↓ other hooks ↓

  // ↓ state ↓
  const [submissionsBarDrawer, setSubmissionsBarDrawer] = useState({
    open: false,
    type: null,
    data: null
  });
  const [searchTypeFilter, setSearchTypeFilter] = useState(ACTIVE_SEARCHES_TYPE_MAP.RETAINED);

  const [activeSearchesUserSelection, setActiveSearchesUserSelection] = useState();

  // ↓ effects ↓
  useEffect(() => {
    if (!searches) {
      const fetch = async () => {
        await dispatch.searches.fetchActiveSearches();
      };

      fetch();
    }
  }, [searches, dispatch]);

  useEffect(() => {
    if (user) setActiveSearchesUserSelection(prettyName(user));
  }, [user]);

  const currentOwner = useMemo(() => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`
    };
  }, [user]);

  const activeSearchesUserSearches = useMemo(() => {
    if (internalUsers?.errorCode > HTTP_STATUS_CODES.SUCCESS) return;

    if (!activeSearchesUserSelection && !size(internalUsers)) return [];

    const activeSearchesUser = internalUsers?.find(
      (internalUser) => prettyName(internalUser) === activeSearchesUserSelection
    );

    return (
      activeSearchesUser &&
      searches?.filter((search) => {
        // const pipelineSearch = _.get(search, ["type"]) === 4;

        const userIsOwner = search.owner?.id === activeSearchesUser.id;
        const userIsAssignedUser = !!find(
          search.assignedUsers?.data,
          (assignedUser) => assignedUser.id === activeSearchesUser.id
        );
        const matchesSearchType = search.type === searchTypeFilter;

        return (userIsOwner || userIsAssignedUser) && matchesSearchType;
      })
    );
  }, [searches, activeSearchesUserSelection, internalUsers, searchTypeFilter]);

  const compactSearchesTableData = useMemo(() => {
    if (user && activeSearchesUserSearches && searchesSubmissions) {
      return makeCompactSearchesTableData({
        searches: activeSearchesUserSearches,
        searchesSubmissions,
        searchesCalls,
        currentOwner
      });
    }
  }, [user, activeSearchesUserSearches, searchesSubmissions, currentOwner]);

  const fetchActiveSearchesData = useCallback(() => {
    const fetch = async () => {
      await dispatch.searches.fetchActiveSearches();
    };

    fetch();
  }, [dispatch]);

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setSearchTypeFilter(newFilter);
    }
  };
  // ↓ renders ↓

  return (
    <>
      <DashboardModuleCard
        title="Active Searches"
        badge={size(activeSearchesUserSearches)}
        filter={
          activeSearchesUserSelection &&
          internalUsers && (
            <>
              <ToggleButtonGroup
                value={searchTypeFilter}
                exclusive
                onChange={handleFilterChange}
                aria-label="search type filter"
                style={{ height: 36, marginRight: 10 }}
              >
                <ToggleButton
                  key={ACTIVE_SEARCHES_LABELS_MAP.RETAINED}
                  value={ACTIVE_SEARCHES_TYPE_MAP.RETAINED}
                  aria-label={ACTIVE_SEARCHES_LABELS_MAP.RETAINED.toLowerCase()}
                  sx={{ textTransform: "none" }}
                >
                  {ACTIVE_SEARCHES_LABELS_MAP.RETAINED}
                </ToggleButton>
                <ToggleButton
                  key={ACTIVE_SEARCHES_LABELS_MAP.CONTINGENT}
                  value={ACTIVE_SEARCHES_TYPE_MAP.CONTINGENT}
                  aria-label={ACTIVE_SEARCHES_LABELS_MAP.CONTINGENT.toLowerCase()}
                  sx={{ textTransform: "none" }}
                >
                  {ACTIVE_SEARCHES_LABELS_MAP.CONTINGENT}
                </ToggleButton>
                <ToggleButton
                  key={ACTIVE_SEARCHES_LABELS_MAP.PENDING}
                  value={ACTIVE_SEARCHES_TYPE_MAP.PENDING}
                  aria-label={ACTIVE_SEARCHES_LABELS_MAP.PENDING.toLowerCase()}
                  sx={{ textTransform: "none" }}
                >
                  {ACTIVE_SEARCHES_LABELS_MAP.PENDING}
                </ToggleButton>
              </ToggleButtonGroup>
              {isAdmin && (
                <FilterDropdown
                  style={{ height: 36, width: 180, marginRight: 0, borderWidth: 0 }}
                  fontSize={14}
                  initialSelected={prettyName(user)}
                  options={
                    superAccess(user)
                      ? internalUsers?.map((internalUser) => prettyName(internalUser))
                      : [prettyName(user)]
                  }
                  maxSelected={1}
                  handleSetSelectedStatuses={(selected) => {
                    setActiveSearchesUserSelection(selected);
                  }}
                  subheader={false}
                />
              )}
            </>
          )
        }
        refresh={true}
        refreshing={loading}
        onRefresh={() => fetchActiveSearchesData()}
        bottomContent={
          <Box>
            {compactSearchesTableData && (
              <Box>
                <ActiveSearchesTableCompact
                  loading={loading}
                  handleDateRangeSelectionChange={handleDateRangeSelectionChange}
                  rangeSelectionMap={rangeSelectionMap}
                  rangeSelection={rangeSelection}
                  setRangeSelection={(payload) => dispatch.app.setRangeSelection(payload)}
                  customDateRange={customDateRange}
                  setCustomDateRange={(payload) => dispatch.app.setCustomDateRange(payload)}
                  currentOwner={currentOwner}
                  skipPageReset={false}
                  handleSubmissionsBarClick={({ data, submissions, job }) => {
                    setSubmissionsBarDrawer({
                      open: true,
                      type: job?.title,
                      data: { submissions, initialStatusGroup: data.id }
                    });
                  }}
                  data={compactSearchesTableData}
                />
              </Box>
            )}
          </Box>
        }
      />
      <SubmissionsBarDrawer
        componentTitle="Active Searches"
        open={submissionsBarDrawer}
        toggleDrawer={setSubmissionsBarDrawer}
      />
    </>
  );
}
