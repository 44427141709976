/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";

const Tooltip = ({ pointData, filteredDataByRecruiter, theme }) => {
  return (
    <Box
      style={{
        background: "white",
        padding: "9px 12px",
        border: "1px solid #ccc"
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Typography
          style={{
            fontSize: 10,
            fontWeight: "bold",
            paddingBottom: theme.spacing(0.5),
            width: 30
          }}
        >
          {`[${pointData.data.yFormatted}]`}
        </Typography>
        <Typography
          style={{
            fontSize: 10,
            fontWeight: "bold",
            paddingBottom: theme.spacing(0.5)
          }}
        >
          Submission(s) added on {pointData.data.xFormatted}
        </Typography>
      </Box>

      {pointData.serieId === "Team" &&
        filteredDataByRecruiter?.map((owner, index) => {
          const ownerName = owner.id;
          const ownerData = owner.data;
          const ownerDateData = ownerData?.find((od) => od.x === pointData.data.xFormatted);

          if (!ownerDateData) return null;

          return (
            <Box key={index} display="flex" alignItems="center" justifyContent="flex-start">
              <Typography
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  paddingBottom: theme.spacing(0.5),
                  width: 30
                }}
              >
                {`[${ownerDateData.y}]`}
              </Typography>
              <Typography
                style={{
                  fontSize: 10,
                  paddingBottom: theme.spacing(0.5)
                }}
              >
                {ownerName}
              </Typography>
            </Box>
          );
        })}
    </Box>
  );
};

export { Tooltip };
