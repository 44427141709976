/**
 * Remove control characters.
 *
 * @param {*} str
 * @returns
 */
const removeControlCharacters = (str) =>
  str
    .replace(/\\n|\\t|\\r|\\f|\\v/g, "") // Remove \n, \t, \r, \f, \v saved as string.
    .replace(/\\["'\\]/g, "") // Remove \" \' y \ if it are skipped.
    // eslint-disable-next-line no-control-regex
    .replace(/[\x00-\x1F\x7F]/g, ""); // Remove Control Characters.

/**
 * Check if some string have HTML elements.
 *
 * @param {String} str
 * @returns
 */
const containsHtml = (str) => {
  return /<[^>]+>/.test(str);
};

/**
 * Remove HTML.
 *
 * @param {*} htmlString
 * @returns
 */
const removeHtmlTags = (htmlString) => {
  if (!containsHtml(htmlString)) {
    return htmlString;
  }

  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  const cleanText = doc.body.textContent.trim();

  const cleanTest = JSON.stringify(`${cleanText}`); // return JSON.stringify to skip new lines.

  return removeControlCharacters(cleanTest);
};

/**
 * Create CSV.
 *
 * @param {*} data
 * @returns
 */
const handleExport = (data) => {
  if (!data?.[0]) return;

  // 1. convert to CSV
  const headers = Object.keys(data[0]); // Asume every object has the same key.
  const rows = data.map((row) => headers.map((header) => removeHtmlTags(row[header])).join(","));

  const csvContent = [headers.join(","), ...rows].join("\n");

  // 2. create blob and link to download.
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  return url;
};

export const UTILS = { handleExport };
