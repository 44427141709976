/* eslint-disable react/prop-types */
import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { prettyName } from "../../../helpers/user";
import { colors, constants } from "../../../styles/config";
import { Line } from "./Line";
import { Tooltip } from "./Tooltip";
import { CustomSymbol } from "./CustomSymbol";

// @TODO [salva@03-18-2025] [#VA2-150] create shared UTILS.
import { UTILS } from "@/views/Internal/Contracts/utils";

import { CONFIG } from "./config";

// @TODO [salva@03-18-2025] [#VA2-151] create shared DATE_UTILS.
const DATE_FORMAT = "YYYY-MM-DD";
const formatDate = (date) => moment(date).format(DATE_FORMAT);

export const SubmissionsChart = ({
  currentlyViewing,
  submissionView,
  setCurrentlyViewing,
  handleDataPointClick
}) => {
  const theme = useTheme();
  const { submissions, currentJob } = useSelector((state) => state.jobs);
  const [csv, setCsv] = useState();

  const getSubmissionsForDate = (array, date) => {
    return array.filter((sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date);
  };

  const getOwnerSubmissionsForDate = (owner, array, date) => {
    return array.filter(
      (sub) =>
        `${sub.sendingUser.firstName} ${sub.sendingUser.lastName}` === owner &&
        moment(sub.dateAdded).format("YYYY-MM-DD") === date
    );
  };

  const sendingUserOptions = useMemo(() => {
    const optionsSet = new Set();

    const uniqueOptions = submissions
      ?.map((sub) => sub.sendingUser)
      .filter((user) => {
        if (!user.id || optionsSet.has(user.id)) {
          return false;
        }
        optionsSet.add(user.id);
        return true;
      });

    return uniqueOptions;
  }, [submissions]);

  const events = currentJob && [
    {
      title: "Engagement",
      start: formatDate(currentJob.engagementDate),
      color: colors.gray43
    },
    {
      title: "Launch",
      start: formatDate(currentJob.launchDate),
      color: colors.variner
    },
    {
      title: "Slate Delivery",
      start: formatDate(currentJob.slateDeliveryDate),
      color: colors.darkerGray
    },
    {
      title: "Today",
      start: moment().format("YYYY-MM-DD"),
      color: colors.varinerGold
    }
  ];

  const eventDates = useMemo(() => {
    return (
      currentJob && {
        engagement: formatDate(currentJob.engagementDate),
        launch: formatDate(currentJob.launchDate),
        slateDelivery: formatDate(currentJob.slateDeliveryDate),
        start: formatDate(currentJob.startDate),
        present: formatDate(new Date())
      }
    );
  }, [events]);

  const getUniqueDates = (submissions) => {
    const datesSet = new Set();
    const uniqueDates = submissions
      ?.map((sub) => ({ x: formatDate(sub.dateAdded) }))
      .filter((sub) => {
        if (!sub.x || datesSet.has(sub.x)) {
          return false;
        }
        datesSet.add(sub.x);
        return true;
      });

    return uniqueDates;
  };

  const groupedByRecruiter = useMemo(() => {
    const groupObj =
      sendingUserOptions &&
      sendingUserOptions.reduce((acc, curr) => {
        const userSubmissions = submissions.filter(
          (submission) => submission.sendingUser.id === curr.id
        );

        return { ...acc, [curr.id]: { data: userSubmissions } };
      }, {});

    return groupObj;
  }, [sendingUserOptions, submissions]);

  const dataAll = useMemo(() => {
    if (submissions) {
      const uniqueDates = getUniqueDates(submissions);

      const aggregatedByDate =
        uniqueDates &&
        uniqueDates.map((date) => {
          const submissionsAddedOnDate = submissions.filter(
            (sub) => formatDate(sub.dateAdded) === date.x
          );

          return {
            ...date,
            y: submissionsAddedOnDate.length
          };
        });

      const sorted =
        aggregatedByDate &&
        aggregatedByDate.sort((a, b) => {
          return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

      return sorted ? { id: "Team", color: "#1a444a", data: sorted } : null;
    }
  }, [submissions]);

  const dataByRecruiter = useMemo(() => {
    const formattedId = (value) => {
      const foundUser = sendingUserOptions.find((user) => user.id === parseInt(value));
      if (!foundUser) return value;

      return prettyName(foundUser);
    };

    const dataArray =
      groupedByRecruiter &&
      Object.keys(groupedByRecruiter).map((id) => {
        const submissionsArray = groupedByRecruiter[id].data;

        const datesSet = new Set();

        const uniqueDates =
          submissionsArray &&
          submissionsArray
            .map((sub) => ({
              x: moment(sub.dateAdded).format("YYYY-MM-DD")
            }))
            .filter((sub) => {
              if (!sub.x || datesSet.has(sub.x)) {
                return false;
              }
              datesSet.add(sub.x);
              return true;
            });

        const aggregatedByDate = uniqueDates.map((date) => {
          const submissionsAddedOnDate = submissionsArray.filter(
            (sub) => moment(sub.dateAdded).format("YYYY-MM-DD") === date.x
          );

          return {
            ...date,
            y: submissionsAddedOnDate.length
          };
        });

        const sorted = aggregatedByDate.sort((a, b) => {
          return moment(a.x).valueOf() - moment(b.x).valueOf();
        });

        const formattedName = formattedId(id);

        return {
          id: formattedName,
          color: constants.colorsForData[formattedName],
          data: sorted
        };
      });

    return dataArray;
  }, [groupedByRecruiter, sendingUserOptions]);

  const filteredDataByRecruiter = useMemo(() => {
    if (currentlyViewing) {
      return dataByRecruiter.filter(
        (data) => data.id === `${currentlyViewing.firstName} ${currentlyViewing.lastName}`
      );
    } else {
      return dataByRecruiter;
    }
  }, [dataByRecruiter, currentlyViewing]);

  const data = submissionView === "recruiter" ? filteredDataByRecruiter : [dataAll];

  useEffect(() => {
    if (!data) return;

    let adjustKeys = [];

    if (submissionView === "recruiter") {
      adjustKeys = data.flatMap(({ data, id: recruiter }) =>
        data.map(({ x, y }) => ({ date: x, submissions: y, recruiter }))
      );
    }

    if (submissionView === "team") {
      adjustKeys = data[0]?.data.map(({ x, y }) => ({ date: x, submissions: y }));
    }

    const csv = UTILS.handleExport(adjustKeys);

    setCsv(csv);
  }, [submissionView, filteredDataByRecruiter, dataAll]);

  const handleViewingChange = (userName) => {
    const foundSendingUser = sendingUserOptions.find((user) => {
      return `${user.firstName} ${user.lastName}` === userName;
    });

    setCurrentlyViewing(foundSendingUser);
  };

  const defaultResponsiveLine = {
    anchor: "top-left",
    direction: "row",
    justify: false,
    translateX: -20,
    translateY: -50,
    itemsSpacing: 0,
    itemDirection: "left-to-right",
    itemWidth: 180,
    itemHeight: 20,
    itemOpacity: 0.75,
    symbolSize: 16,
    symbolShape: "square",
    onClick: ({ id }) => {
      handleViewingChange(id);
    },
    effects: [
      {
        on: "hover",
        style: {
          itemBackground: "rgba(0, 0, 0, .03)",
          itemOpacity: 1
        }
      }
    ]
  };

  const ResponsiveLineLegend = currentlyViewing ? [] : [defaultResponsiveLine];

  if (!dataAll || !dataByRecruiter) return null;

  const handleClick = (pointData) => {
    let daySubmissions;

    if (pointData.serieId === "Team") {
      daySubmissions = getSubmissionsForDate(submissions, pointData.data.xFormatted);
    } else {
      daySubmissions = getOwnerSubmissionsForDate(
        pointData.serieId,
        submissions,
        pointData.data.xFormatted
      );
    }
    handleDataPointClick({
      id: pointData.serieId,
      date: pointData.data.xFormatted,
      submissions: daySubmissions
    });
  };

  const getColors = ({ id }) => {
    const match = dataByRecruiter.find((obj) => obj.id === id);
    return (match && match.color) || "white";
  };

  const layers = [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    Line,
    "points",
    "slices",
    "mesh",
    "legends",
    "sliceTooltip",
    "tooltip"
  ];

  const FILE_NAME = `submission-over-time-by-${submissionView}(job-${currentJob?.id ?? 0}).csv`;

  return (
    <>
      {csv && (
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            variant="contained"
            component="a"
            href={csv}
            download={FILE_NAME}
            style={{ margin: "14px" }}
          >
            Download CSV
          </Button>
        </div>
      )}

      <ResponsiveLine
        animate={false}
        data={data}
        margin={CONFIG.margins}
        colors={getColors}
        theme={CONFIG.theme}
        xScale={CONFIG.xScale}
        xFormat={CONFIG.xFormat}
        yScale={CONFIG.yScale}
        axisLeft={CONFIG.axisLeft}
        axisBottom={CONFIG.getAxisBottom(eventDates)}
        onClick={handleClick}
        tooltip={({ point: pointData }) => {
          return !pointData ? null : (
            <Tooltip
              pointData={pointData}
              theme={theme}
              filteredDataByRecruiter={filteredDataByRecruiter}
            />
          );
        }}
        pointSymbol={(data) => (
          <CustomSymbol data={data} submissionView={submissionView} events={events} />
        )}
        pointBorderWidth={2}
        pointColor={{ theme: "background" }}
        pointBorderColor={{ from: "serieColor", modifiers: [] }}
        enableSlices={false}
        curve="natural"
        pointSize={8}
        pointLabelYOffset={-12}
        enableArea={false}
        layers={layers}
        legends={ResponsiveLineLegend}
        enablePointLabel
        useMesh
      />
    </>
  );
};
